<template>
  <section class="article-binding-section">
    <h3 class="article-edit-header">{{ $t('article.binding') }}</h3>

    <div class="row">
      <div v-if="article.originalDocumentId" class="col-12 d-flex flex-column mb-3">
        <span class="article-binding-section__text mb-1">{{ $t('article.original_article') }}</span>
        <button class="btn btn-info" @click="handleOriginalArticle">{{ originalArticleText }}</button>
      </div>

      <div v-if="article.sourceAgencyId" class="col-12 d-flex flex-column mb-3">
        <span class="article-binding-section__text mb-1">{{ $t('article.agency_link') }}</span>
        <button class="btn btn-info" @click="handleAgencyLink">{{ agencyText }}</button>
      </div>
    </div>

    <div v-if="article.type !== 'external'">
      <app-article-url-info
        v-if="article.id"
        :article="article"
        :hide-refresh-url-button="articleEditDisabled"
      />
    </div>
  </section>
</template>

<script>

import appArticleUrlInfo from '@/components/article/ArticleUrlInfo.vue'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'ArticleBinding',
  components: { appArticleUrlInfo },
  data () {
    return {}
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    originalArticleText () {
      return this.$t('article.original_article_id') + ': ' + this.article.originalDocumentId
    },
    agencyText () {
      return this.$t('article.agency_id') + ': ' + this.article.sourceAgencyId
    }
  },
  methods: {
    async handleOriginalArticle () {
      const article = await this.loadArticle()
      if (!article || !article[0]) {
        NotifyService.setErrorMessage(this.$t('notify.fetch_article_error'))
        return
      }
      this.$router.push('/article/' + article[0].id)
    },
    handleAgencyLink () {
      // TODO where to redirect?
    },
    async loadArticle () {
      return await this.$store.dispatch('article/fetchByDocumentId', { documentId: this.article.originalDocumentId })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-binding-section {
  &__text {
    @include font(400 14px "Roboto");
    color: #8A96AC;
  }
}
</style>
