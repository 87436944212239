<template>
  <div :class="['switch-container-aside', `switch-container-aside--${this.size}`]">
    <label :class="['switch', `switch--${this.size}`]">
      <input
        type="checkbox"
        :id="id"
        v-model="localValue"
        :disabled="disabled"
        :data-test="dataAttr ? dataAttr : id"
      />
      <span :class="sliderClass"/>
    </label>
    <span> {{ label }}</span>
    <hr v-if="divider" class="switch-container-aside__divider">
  </div>
</template>

<script>
export default {
  name: 'SwitchCheckboxAside',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      default: 'lg',
      validator: function (value) {
        return ['md', 'lg'].includes(value)
      }
    },
    divider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        if (!this.disabled) {
          this.$emit('input', val)
        }
      }
    },
    sliderClass () {
      const classes = ['switch__slider', 'switch__slider--' + this.size]
      !this.disabled && classes.push('switch__slider--disabled')
      return classes
    }
  }
}
</script>
<style scoped lang="scss">
.switch-container-aside {
  display: flex;
  align-items: center;
  gap: 1rem;

  &--md {
    @include font(400 14px "Roboto");
  }

  &__divider {
    flex: 1 1 100%;
    border-top-width: 2px;
    margin: 0;
  }
}

.switch {
  position: relative;
  display: inline-block;
  flex: 0 0 auto;

  &--lg {
    width: rem(60px);
    height: rem(34px);
  }

  &--md {
    width: rem(40px);
    height: rem(20px);
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: rem(34px);

    &--disabled {
      cursor: default;
    }

    &:before {
      position: absolute;
      content: "";
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    &--lg {
      &:before {
        height: rem(26px);
        width: rem(26px);
        left: rem(30px);
        bottom: rem(4px);
      }
    }

    &--md {
      &:before {
        height: rem(16px);
        width: rem(16px);
        right: rem(2px);
        bottom: rem(2px);
      }
    }
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .switch__slider {
  background-color: #4caf50;
}

input:checked + .switch__slider {
  &--lg {
    &:before {
      -webkit-transform: translateX(-26px);
      -ms-transform: translateX(-26px);
      transform: translateX(-26px);
    }
  }

  &--md {
    &:before {
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px);
    }
  }
}
</style>
