<template>
  <section>
    <h3 class="article-edit-header">{{ $t('article.main_settings') }}</h3>
    <div class="row">
      <div :class="vlm ? 'col-12' : 'col-6'">
        <SiteSelect
          v-if="vlm"
          v-model="article.site"
          :options="sites"
          :disabled="articleEditDisabled"
          id="article_site"
          :label="$t('article.site')"
          required
          @input="$emit('siteChanged')"
        />

        <app-select
          v-else
          v-model="article.site"
          :disabled="articleEditDisabled"
          :options="sites"
          no-empty-value
          id="article_site"
          :label="$t('article.site')"
          required
          @change="$emit('siteChanged')"
        >
        </app-select>
      </div>
      <div :class="vlm ? 'col-12 my-3' : 'col-6'">
        <label for="article_mainRubric">
          {{ $t('article.main_rubric') }}
          <span class="required">*</span>
        </label>
        <select v-model="article.mainRubric" id="article_mainRubric" class="form-control"
                :disabled="articleEditDisabled">
          <option
            v-for="(rubric, index) in rubricsBySite"
            :key="`item-${index}`"
            :value="rubric.id"
            :selected="isRubricSelected(rubric, index)"
          >
            <template v-if="rubric.lvl === 1">
              &nbsp;&nbsp;&nbsp;&nbsp;
            </template>
            <template v-if="rubric.lvl === 2">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </template>
            {{ rubric.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label>{{ $t('article.other_rubrics') }}</label>
      <app-multi-select
        v-model="article.rubrics"
        :options="rubrics"
        label="title"
        track-by="id"
        id="article_rubrics"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>

    <div v-if="vlm" class="row">
      <div class="col-12">
        <hr>
      </div>

      <label class="col-12">{{ $t('article.badges') }}</label>
      <app-multiSelect
        class="col-12 mb-3"
        v-model="article.articleBadges"
        :options="optionsBadges"
        label="title"
        track-by="id"
        id="article_badges"
        :disabled="articleEditDisabled"
      />
    </div>

    <div v-if="showPaidContentCheckbox" class="form-group">
      <app-checkbox
        v-model="article.setting.paidContent"
        id="article_setting_paidContent"
        :label="$tc('article.paid_content')"
        :disabled="articleEditDisabled"
      >
      </app-checkbox>
    </div>

    <div class="row m-b-20">
      <div class="col-lg-12">
        <label for="article_external_orderDate">
          {{ $t('article.order_date') }}
        </label>
      </div>
      <div class="col-lg-8">
        <app-datepicker
          v-model="article.orderDate"
          @blur="validations.article.orderDate.$touch()"
          :error="validations.article.orderDate.$error"
          id="article_external_orderDate"
          no-label
          disable-form-group
          :disabled="articleEditDisabled"
        >
        </app-datepicker>
      </div>
      <div class="col-lg-4">
        <div class="orderDate-set-now">
          <span @click="setOrderDateToNow">{{ $t('article.set_order_date_to_now') }}</span>
        </div>
      </div>
    </div>

    <div v-if="vlm" class="row">
      <label class="col-12" for="article_external_orderDate">{{ $t('article.actualization_date') }}</label>
      <div class="col-12 mb-3">
        <app-datepicker
          v-model="article.actualisationDate"
          id="article_external_actualisationDate"
          no-label
          disable-form-group
          :disabled="articleEditDisabled"
        />
      </div>
    </div>

    <div v-if="vlm" class="row">
      <div class="col-lg-12">
        <app-checkbox
          v-model="article.flag.hiddenOnHp"
          id="article_hiddenOnHp"
          :label="$t('article.hiddenOnHp')"
          :disabled="articleEditDisabled"
        />
      </div>
    </div>
    <div v-if="vlm" class="row">
      <div class="col-lg-12">
        <app-checkbox
          v-model="enabledFromWeb"
          id="article_enabledFromWeb"
          :label="$t('article.enabled_from_web')"
          :disabled="articleEditDisabled"
        >
        </app-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <app-checkbox
          v-model="showPublishedUntil"
          id="article_showPublishedUntil"
          :label="$t('article.set_published_until')"
          :disabled="articleEditDisabled"
        >
        </app-checkbox>
      </div>
      <div class="col-lg-8">
        <app-datepicker
          v-if="showPublishedUntil"
          v-model="article.publishedUntil"
          id="article_publishedUntil"
          no-label
          :disabled="articleEditDisabled"
        >
        </app-datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <app-checkbox
          v-model="showPublishedSinceLocal"
          id="quiz_showPublishedSince"
          :label="$t('article.postpone')"
          :disabled="articleEditDisabled"
          @input="handleShowPublishedSince"
        >
        </app-checkbox>
      </div>
      <div class="col-lg-8">
        <app-datepicker
          v-if="showPublishedSince"
          v-model="article.publishedSince"
          id="article_publishedSince"
          disable-form-group
          no-label
          :disabled="articleEditDisabled"
        >
        </app-datepicker>
      </div>
    </div>

    <ArticleGeneralSettings
      v-if="!vlm"
      :ai-assisted-locked="aiAssistedLocked"
      :article-site-name="articleSiteName"
      :authors="authors"
      :transition-errors="transitionErrors"
      :data-loaded="dataLoaded"
      :article="article"
    />

    <hr v-if="vlm">

    <ArticleMetaInfo
      v-if="vlm"
      :article-site-name="articleSiteName"
      :transition-errors="transitionErrors"
      :data-loaded="dataLoaded"
      :article="article"
    />

    <div class="form-group" v-if="!vlm && article.type !== 'external'">
      <app-article-url-info
        v-if="article.id"
        :article="article"
        :hide-refresh-url-button="articleEditDisabled"
      >
      </app-article-url-info>
    </div>
  </section>
</template>
<script>

import appArticleUrlInfo from '@/components/article/ArticleUrlInfo.vue'
import appSelect from '@/components/form/select/Select.vue'
import appCheckbox from '@/components/form/Checkbox.vue'
import appMultiSelect from '@/components/form/select/MultiSelect.vue'
import appDatepicker from '@/components/form/Datepicker.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'
import ArticleGeneralSettings from '@/components/article/section/ArticleGeneralSettings.vue'
import ArticleMetaInfo from '@/components/article/section/ArticleMetaInfo.vue'
import { ARTICLE_BADGES } from '@/model/ArticleModel'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'ArticleSettings',
  components: {
    SiteSelect,
    ArticleMetaInfo,
    ArticleGeneralSettings,
    appDatepicker,
    appMultiSelect,
    appCheckbox,
    appSelect,
    appArticleUrlInfo
  },
  data () {
    return {
      generatedKeywordsView: [],
      searchedTag: '',
      isLoading: false,
      showPublishedUntil: false,
      showPublishedSinceLocal: this.showPublishedSince
    }
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    articleSiteName: {
      type: String,
      required: true
    },
    dataLoaded: {
      type: Boolean,
      required: true
    },
    transitionErrors: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    showPublishedSince: {
      type: Boolean,
      required: true
    },
    aiAssistedLocked: {
      type: Boolean,
      required: true
    },
    showPaidContentCheckbox: {
      type: Boolean,
      required: true
    },
    rubricsBySite: {
      type: Array,
      required: true
    },
    authors: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    rubrics () {
      if (this.vlm) {
        return this.$store.getters['rubric/rubricsWithSite'].filter(item => item.site === this.article.site)
      } else {
        return this.$store.getters['rubric/rubricsWithSite']
      }
    },
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_ARTICLE, this.article)
    },
    optionsBadges () {
      return ARTICLE_BADGES.map(badge => {
        return { id: badge, title: this.$t(`article.badges_options.${badge}`) }
      })
    },
    enabledFromWeb: {
      get () {
        return !this.article.setting.enabledFromWeb
      },
      set (value) {
        this.article.setting.enabledFromWeb = !value
      }
    }
  },
  mounted () {
    // showPublishedUntil
    if (new Date(this.article.publishedUntil) > new Date() && new Date(this.article.publishedUntil) < new Date('2100-01-01T00:00:00.000000Z')) {
      this.showPublishedUntil = true
    }
  },
  watch: {
    showPublishedUntil (showPublishedUntil) {
      if (showPublishedUntil === false) {
        this.article.publishedUntil = ''
      }
    }
  },
  methods: {
    setOrderDateToNow () {
      if (!this.articleEditDisabled) {
        this.article.orderDate = moment().utc().toISOString()
      }
    },
    isRubricSelected (rubric, index) {
      const selectedRubrics = this.rubricsBySite.filter(rubric => {
        if (rubric.id === this.article.mainRubric) {
          return rubric
        }
      })
      if (selectedRubrics.length === 0 && index === 0) {
        this.article.mainRubric = rubric.id
        return true
      }
      return rubric.id === this.article.mainRubric
    },
    handleShowPublishedSince (value) {
      this.$emit('showPublishedSinceChanged', value)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
