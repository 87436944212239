<template>
  <BaseModal
      :title="!location.address ? $t('article.geolocation_insert') : $t('article.geolocation_edit')"
      @close="close"
  >
    <template v-slot:body>
      <div class="autocomplete-wrapper">
        <MapComponent
            :geo="coordinates"
            :zoom="locationSelected.zoom"
            class="mb-2"
            @zoomChange="handleZoom"
            @mapClick="handleCoordinates"
        />

        <div class="row">
          <Input
              v-model="locationSelected.address"
              class="col-lg-10"
              :label="$t('address.address')"
              @input="handleAddressInput"
              disable-form-group
          />

          <div class="col-lg-2 d-flex mt-4">
            <button type="button" class="btn btn-info flex-fill" @click="remove">
              {{ $t('article.modal.cancel') }}
            </button>
          </div>
        </div>

        <div class="autocomplete-results" v-if="addressSuggestions.length">
          <ul>
            <li
                v-for="(item, index) in addressSuggestions"
                :key="`${item.id}_sortedAddressSuggestions_${index}`"
                :class="{'item-from-address-input':item.isFromInput}"
                @mousedown.prevent="handleSelect(item)"
            >
              <span v-if="item.isFromInput">"{{ item.name }}"</span>
              <span v-else>{{ item.name }}</span>
              <span v-if="item.locationDetails">, ({{ item.locationDetails }})</span>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button type="button" class="btn" @click="close" data-test="before_route_leave_btn_no">
        {{ $t('article.modal.cancel') }}
      </button>

      <button type="button" class="btn btn-info" @click="submit" data-test="before_route_leave_btn_yes">
        {{ $t('article.modal.insert') }}
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '../shared/Modal'
import Input from '@/components/form/inputs/Input.vue'
import Location from '@/components/mixins/Location'
import appConfig from '@/config'
import MapComponent from '@/components/address/MapComponent.vue'
import { cloneDeep } from 'lodash'
import LocationModel, { DEFAULT_LOCATION_COORDINATES, DEFAULT_LOCATION_ZOOM } from '@/model/LocationModel'

export default {
  name: 'ArticleLocationModal',
  mixins: [Location],
  components: {
    MapComponent,
    Input,
    BaseModal: Modal
  },
  data () {
    return {
      API_KEY: appConfig.mapyCZ.xApiKey(),
      API_URL: appConfig.mapyCZ.apiUrl(),
      addressSuggestions: [],
      locationSelected: { ...this.location, zoom: this.location.zoom || DEFAULT_LOCATION_ZOOM }
    }
  },
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    coordinates () {
      return this.locationSelected.geo || DEFAULT_LOCATION_COORDINATES
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    remove () {
      this.locationSelected = cloneDeep(LocationModel)
    },
    formatPosition (item) {
      return item.data && item.data.position ? `${item.data.position.lat},${item.data.position.lon}` : ''
    },
    formatAddress (item) {
      return `${item.name || ''}${item.locationDetails ? ', ' + item.locationDetails : ''}`
    },
    clearSuggestions () {
      this.addressSuggestions = []
    },
    handleSelect (item) {
      this.locationSelected.geo = this.formatPosition(item)
      this.locationSelected.address = this.formatAddress(item)
      this.clearSuggestions()
    },
    submit () {
      this.$emit('submit', this.locationSelected)
      this.close()
    },
    handleZoom (item) {
      this.locationSelected.zoom = item
    },
    handleCoordinates (item) {
      this.locationSelected.geo = `${item.lat},${item.lng}`
      this.locationSelected.address = this.formatAddress(item.address)
    },
    handleAddressInput () {
      if (!this.locationSelected.address || this.locationSelected.address.length < 3) {
        if (this.locationSelected.address.length === 0) {
          this.clearSuggestions()
        }
      } else {
        this.searchAddress()
      }
    },
    async searchAddress () {
      try {
        this.addressSuggestions = await this.suggestAddress(this.locationSelected.address)
      } catch (error) {
        console.error('Error fetching suggestions:', error)
        this.clearSuggestions()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 250px;
  }

  li {
    padding: 8px 10px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  .item-from-address-input {
    font-style: italic;
    background: #f8f8f8;
  }
}
</style>
