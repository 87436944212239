<template>
  <div>
    <div v-if="article.type === 'longform'">
      <app-select
        v-model="article.setting.layout"
        :options="articleLayoutValues"
        no-empty-value
        id="article_layout"
        :label="$t('article.layout')"
        :disabled="articleEditDisabled"
      >
      </app-select>
    </div>
    <!-- VLM GENEEA TAGS -->
    <GeneeaTags
      v-if="vlm && article.type !== this.ARTICLE_TYPE_EXTERNAL"
      :error="transitionErrors.geneeaTags"
      :article="article"
    />
    <div class="form-group" v-if="!vlmDotyk && article.type !== 'external'">
      <div class="row">
        <div class="col-lg-5">
          <label>{{ $t('article.tags') }}</label>
          <!-- Keywords -->
          <AiGenerator
            v-if="!vlm && dataLoaded"
            type="keyword"
            class="keyword-generator-button"
            :article="article"
            :body-blocks="nmhEditorStoreData.blocks"
            :submit-button-label="$t('article.ai_service.generate_from_ai')"
            :open-modal-with-results="true"
            :unique-keywords="generatedKeywordsView"
            @generated="onAiGeneratedKeywords"
            @addTags="addTags"
            @aiAssistLock="$emit('aiAssistLock', true)"
          />
        </div>
        <div class="col-lg-7 create-tag-button">
          <app-create-tag-button
            v-if="searchedTag && !articleEditDisabled"
            :searchedTag="searchedTag"
            @set-tag="addTag"
          >
          </app-create-tag-button>
        </div>
      </div>
      <app-multi-select
        v-model="article.expanded.tags"
        :options="tags"
        :custom-label="tag => `${tag.title} (${tag.articleCount})`"
        track-by="id"
        :preselect-first="true"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="300"
        :limit="99999"
        :max-height="600"
        :show-no-results="false"
        :disabled="articleEditDisabled"
        open-direction="bottom"
        @search-change="findTag"
        @remove="removeTag"
        id="article_tags"
      >
      </app-multi-select>
    </div>
    <!-- VLM EDITORIAL TAGS -->
    <EditorialTags
      v-if="vlm && article.type !== 'external'"
      :article="article"
      :error="transitionErrors.geneeaTags"
    />
    <app-article-selected-topics
      v-if="!vlmDotyk"
      :selectedTopics="article.expanded.selectedTopics"
      :article-tags="article.expanded.tags"
      :article-type="article.type"
      :article-site="article.site"
      :article-site-name="articleSiteName"
      @set-data="setSelectedTopics($event)"
    >
    </app-article-selected-topics>
    <label>{{ $t('article.flags') }}</label>
    <app-flag-buttons
      :article="article"
    >
    </app-flag-buttons>
    <ThemeSpecialAndSeriesSelect v-if="vlm"
                                 :article="article"
                                 @setSpecial="setSpecial"
                                 @setTheme="setTheme"
                                 @setSeries="setSeries"
    />
    <div v-if="article.type !== 'external'">
      <label>{{ $t('article.settings') }}</label>
      <div class="form-group">
        <app-checkbox
          v-if="!vlmDotyk"
          v-model="article.setting.disableComments"
          id="article_setting_disableComments"
          :label="$t('article.disable_comments')"
          :disabled="articleEditDisabled"
        />
        <app-checkbox
          v-model="article.setting.disableTransfer"
          id="article_setting_disableTransfer"
          :label="$t('article.disable_transfer')"
          :disabled="articleEditDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>

import appCreateTagButton from '@/components/article/ArticleCreateTagButton.vue'
import appSelect from '@/components/form/select/Select.vue'
import appCheckbox from '@/components/form/Checkbox.vue'
import appArticleSelectedTopics from '@/components/article/ArticleSelectedTopics.vue'
import ThemeSpecialAndSeriesSelect from '@/components/themeSpecialAndSeries/themeSpecialAndSeriesSelect.vue'
import appMultiSelect from '@/components/form/select/MultiSelect.vue'
import EditorialTags from '@/components/EditorialTags/EditorialTags.vue'
import GeneeaTags from '@/components/GeneeaTags/GeneeaTags.vue'
import appFlagButtons from '@/components/article/ArticleFlagButtons.vue'
import AiGenerator from '@/components/ai/AiGenerator.vue'
import ArticleTypeMixin from '@/components/mixins/valueObject/ArticleTypeMixin'
import { mapGetters, mapState } from 'vuex'
import { ARTICLE_LAYOUT } from '@/model/ArticleModel'

export default {
  name: 'ArticleMetaInfo',
  components: {
    AiGenerator,
    appFlagButtons,
    GeneeaTags,
    EditorialTags,
    appMultiSelect,
    ThemeSpecialAndSeriesSelect,
    appArticleSelectedTopics,
    appCheckbox,
    appSelect,
    appCreateTagButton
  },
  mixins: [ArticleTypeMixin],
  data () {
    return {
      generatedKeywordsView: [],
      tags: [],
      searchedTag: '',
      isLoading: false
    }
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    dataLoaded: {
      type: Boolean,
      required: true
    },
    transitionErrors: {
      type: Object,
      required: true
    },
    articleSiteName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    ...mapGetters(['vlm', 'vlmDotyk']),
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    articleLayoutValues () {
      return ARTICLE_LAYOUT.map(layout => {
        return { id: layout, title: this.$t(`article.layout.${layout}`) }
      })
    }
  },
  methods: {
    onAiGeneratedKeywords (keywords) {
      if (keywords?.length > 0) {
        this.generatedKeywords = Array.from(new Set(keywords.map(keyword => keyword)))
        this.filterGeneratedKeywords()
      }
    },
    filterGeneratedKeywords () {
      const tagTitles = this.article.expanded.tags.reduce((acc, tag) => {
        acc[tag.title.toLowerCase()] = true
        return acc
      }, {})
      this.generatedKeywordsView = this.generatedKeywords.filter(keyword => !tagTitles[keyword.toLowerCase()])
    },
    findTag (query) {
      this.isLoading = true
      this.searchedTag = ''
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tags = this.$store.getters['tag/list']
          this.searchedTag = query
          this.isLoading = false
        })
    },
    addTag (tag) {
      if (this.article.expanded.tags.some((i) => i.id === tag.id) === false) {
        this.article.expanded.tags.push(tag)
        this.filterGeneratedKeywords()
      }
      this.searchedTag = ''
    },
    addTags (newTags) {
      const existingTagsIds = this.article.expanded.tags.map(tag => tag.id)
      const filteredTags = newTags.filter(tag => !existingTagsIds.includes(tag.id))
      this.article.expanded.tags = [...this.article.expanded.tags, ...filteredTags]

      this.filterGeneratedKeywords()
    },
    removeTag () {
      this.$nextTick(() => this.filterGeneratedKeywords())
    },
    setSelectedTopics (data) {
      this.article.expanded.selectedTopics = data
      this.article.selectedTopics = data.map(topic => topic.id)
    },
    setSpecial (special) {
      this.article.specialArticleGroupId = special ? special.id : null
    },
    setTheme (theme) {
      this.article.themeId = theme ? theme.id : null
    },
    setSeries (series) {
      this.article.seriesId = series ? series.id : null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
