<template>
  <div>
    <div class="form-group" v-if="article.type !== 'external'">
      <label :class="{'error': transitionErrors.authors}">
        {{ $t('article.authors') }}
        <span v-if="vlm" class="required">*</span>
      </label>
      <app-multi-select
        v-model="article.expanded.authors"
        :options="authors"
        :custom-label="getAuthorCustomName"
        track-by="id"
        id="article_authors"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>
    <div class="form-group" v-if="articleSiteName === 'zdravie'">
      <label>{{ $t('article.doctors') }}</label>
      <app-multi-select
        v-model="article.expanded.doctors"
        :options="doctors"
        label="name"
        track-by="id"
        id="article_doctors"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>
    <div class="form-group" v-if="showGardeners">
      <label>{{ $t('article.gardeners') }}</label>
      <app-multi-select
        v-model="article.expanded.doctors"
        :options="gardeners"
        label="name"
        track-by="id"
        id="article_gardeners"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>

    <CounselingFlagComponent
      :article="article"
      :articleEditDisabled="articleEditDisabled"
    />

    <div class="form-group" v-if="false && mainAuthors.length > 1">
      <app-select
        v-model="article.mainAuthor"
        :options="mainAuthors"
        id="article_mainAuthor"
        :label="$t('article.main_author')"
        no-empty-value
        :disabled="articleEditDisabled"
      >
      </app-select>
    </div>

    <div class="form-group" v-if="article.type !== 'external'">
      <app-nature-of-the-article
        :article="article"
        :error="transitionErrors.natureOfTheArticle"
      >
      </app-nature-of-the-article>
    </div>

    <div class="form-group">
      <app-source-type
        v-if="article.type !== 'external'"
        :article="article"
        :error="transitionErrors.sourceType"
      >
      </app-source-type>
      <ArticleAiTools v-if="!vlm && dataLoaded" :article="article" :aiAssistedLocked="aiAssistedLocked"/>
    </div>

    <div class="form-group" v-if="article.type !== 'external'">
      <app-safety-topics
        :article="article"
        :error="transitionErrors.safetyTopics"
      >
      </app-safety-topics>
    </div>

    <div
      class="form-group"
      v-if="article.type !== 'external'"
    >
      <label :class="{'error': transitionErrors.categories}">
        {{ $t('article.categories') }}
        <span class="required">*</span>
      </label>
      <app-multi-select
        v-model="article.expanded.categories"
        :options="categories"
        label="title"
        track-by="id"
        id="article_categories"
        data-attr="content_categories"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>

    <div class="form-group">
      <label :class="{'error': transitionErrors.sharedUsers}">
        {{ $t('article.sharedUsers') }}
        <span class="required">*</span>
        <app-tooltip
          position="absolute"
          :title="$tc('topicOffer.original.tooltip.shared_users')"
        >
        </app-tooltip>
      </label>
      <app-multi-select
        v-model="article.expanded.sharedUsers"
        :options="sharedUsers"
        label="username"
        track-by="id"
        id="article_sharedUsers"
        data-attr="authors_share"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>

    <div class="form-group" v-if="article.type !== 'external'">
      <label>{{ $t('article.owners') }}</label>
      <app-multi-select
        v-model="article.expanded.owners"
        :options="owners"
        label="username"
        track-by="id"
        id="article_owners"
        :disabled="articleEditDisabled"
      >
      </app-multi-select>
    </div>
  </div>
</template>

<script>
// TODO if actualisationDate is filled, hide orderDate
import { mapGetters } from 'vuex'
import appMultiSelect from '@/components/form/select/MultiSelect.vue'
import appSafetyTopics from '@/components/article/ArticleSafetyTopics.vue'
import appSelect from '@/components/form/select/Select.vue'
import appNatureOfTheArticle from '@/components/article/ArticleNatureOfTheArticle.vue'
import appSourceType from '@/components/article/ArticleSourceType.vue'
import CounselingFlagComponent from '@/components/flag/CounselingFlagComponent.vue'
import ArticleAiTools from '@/components/article/ArticleAiTools.vue'
import appTooltip from '@/components/tooltip/Tooltip.vue'

export default {
  name: 'ArticleGeneralSettings',
  components: {
    appTooltip,
    ArticleAiTools,
    CounselingFlagComponent,
    appSourceType,
    appNatureOfTheArticle,
    appSelect,
    appSafetyTopics,
    appMultiSelect
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    dataLoaded: {
      type: Boolean,
      required: true
    },
    transitionErrors: {
      type: Object,
      required: true
    },
    authors: {
      type: Array,
      required: true
    },
    articleSiteName: {
      type: String,
      required: true
    },
    aiAssistedLocked: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['vlm']),
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    doctors () {
      return this.$store.getters['author/all'].filter((author) => author.type === 'doctor')
    },
    gardeners () {
      return this.$store.getters['author/all'].filter((author) => author.type === 'gardener')
    },
    showGardeners () {
      if (this.articleSiteName !== 'zahradkar') {
        return false
      }
      const rubricId = this.article.mainRubric
      let rubric = this.$store.getters['rubric/rubricById'](rubricId)
      if (rubric) {
        if (rubric.parent) {
          rubric = this.$store.getters['rubric/rubricById'](rubric.parent)
        }
        return rubric.slug === 'poradna'
      }
      return false
    },
    mainAuthors () {
      const mainAuthors = []
      this.article.expanded.authors.forEach(author => {
        const mainAuthor = {
          id: author.id,
          title: author.name
        }
        mainAuthors.push(mainAuthor)
      })
      return mainAuthors
    },
    owners () {
      return this.$store.getters['user/all']
    },
    sharedUsers () {
      return this.$store.getters['user/all']
    },
    categories () {
      return this.$store.getters['category/all']
    }
  },
  methods: {
    getAuthorCustomName (author) {
      return this.$store.getters['author/getAuthorCustomName'](author)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
