<template>
  <div class="radio-buttons-container" :class="{'radio-buttons-container--border': border}">
    <span class="radio-buttons-container__header">
      <span class="radio-buttons-container__header__title">{{ label }}</span>
      <hr v-if="labelDivider" class="radio-buttons-container__header__divider">
    </span>

    <div class="radio-buttons-container__options">
      <div v-for="(option, index) in options" :key="index" class="radio-buttons-container__options">
        <input type="radio" :id="option.id" :value=option.value v-model="localValue" :name="name" :disabled="option.disabled || disabled">
        <label :for="option.id">{{ option.title }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelDivider: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true,
      validator: (options) => {
        return options.every(option => option.id && option.title && Object.hasOwn(option, 'value'))
      }
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-buttons-container {
  align-items: flex-start;
  display: flex;
  gap: rem(8px);
  padding: 0 rem(16px);

  &--border {
    border: 1px solid #d1dbe4;
    border-radius: rem(6px);
  }

  &__header {
    align-items: center;
    color: #8A96AC;
    display: flex;
    gap: rem(8px);
    width: 100%;
    @include font(400 14px "Roboto");

    &__title {
      flex: 0 0 auto;
    }

    &__divider {
      flex: 1 1 100%;
      border-top-width: 2px;
      margin: 0;
    }
  }
}
</style>
